import React from "react";
import DevelopmentPageTemplate from "../../templates/development-page";
import { Button, Tooltip, ButtonToolbar, Popover, OverlayTrigger } from "react-bootstrap";
import "../../threeplay.scss";

export default class Index extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      show: false
    }
  }

  render() {
    const overview = (
      <>
        <p>
          Tooltips are used to display additional, related information when a user hovers over an element or focuses it using the keyboard. Tooltips should only be used to display non-crucial information or messaging. 
        </p>
        <p>
          Tooltips are mainly transient and dissapear once a user moves the cursor away from the element or unfocuses it, although they can also dissapear after a certain amount of time.
        </p>
        <p>
          Tooltips should be used sparingly, as too many can cause cognitive overload. Don't use tooltips on actions are have a clear call-to-action, as redundancy is not needed if the copy and messaging outside of the tooltip is clear.
        </p>
        <p>
          <b>Use tooltips with: </b><br/>Buttons, Information Bubbles <i className="fa fa-info-circle"></i>, Links, Icons
        </p>
        <p>
          <b>Do not use tooltips with: </b><br/>Images, Videos, components such as lists and radio buttons that contain copy (instead, use an information bubble)
        </p>
      </>
    );

    const code_basic = (
      <>
        <br />
        <h3>Basic Tooltip</h3>
        <p>Basic implementation of tooltips on common elements.</p>
        <ButtonToolbar>
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="tooltip-top">
                Tooltip Data
              </Tooltip>
            }
          >
            <Button variant="secondary">Tooltip on Top</Button>
          </OverlayTrigger>
        </ButtonToolbar>
        <br />
      </>
    );
    const codeString_basic = `
    <ButtonToolbar>
      <OverlayTrigger
        key="top"
        placement="top"
        overlay={
          <Tooltip id="tooltip-top">
            Tooltip Data
          </Tooltip>
        }
      >
        <Button variant="secondary">Tooltip on Top</Button>
      </OverlayTrigger>
    </ButtonToolbar>
    `;

    const code_popover = (
      <>
        <br />
        <h3>Basic Popover</h3>
        <p>Basic implementation of popovers on common elements.</p>
        <ButtonToolbar>
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Popover id="popover-positioned-top">
                <Popover.Title as="h3">Popover Top</Popover.Title>
                <Popover.Content>
                  <strong>Holy guacamole!</strong> Check this info.
                </Popover.Content>
              </Popover>
            }
          >
            <Button variant="secondary">Popover on Top</Button>
          </OverlayTrigger>
        </ButtonToolbar>
        <br />
      </>
    );
    const codeString_popover = `
    <ButtonToolbar>
      <OverlayTrigger
        key="top"
        placement="top"
        overlay={
          <Popover id="popover-positioned-top">
            <Popover.Title as="h3">Popover Top</Popover.Title>
            <Popover.Content>
              <strong>Holy guacamole!</strong> Check this info.
            </Popover.Content>
          </Popover>
        }
      >
        <Button variant="secondary">Popover on Top</Button>
      </OverlayTrigger>
    </ButtonToolbar>
    `;

    const codes = [
      {
        code: code_basic,
        codeLanguage: "html",
        codeString: codeString_basic
      },
      {
        code: code_popover,
        codeLanguage: "html",
        codeString: codeString_popover
      }
    ]

    const accessibilitySection = (
      <>
        <h5>Tooltips Must Be Keyboard Navigable</h5>
        <p>Tooltips must be focusable and keyboard accessible. If using a tooltip that displays on hover, the element must display the information in the tooltip automatically upon keyboard focus. If using a popover that requires a user to click the element before showing the information in the tooltip, the element must allow you to activate the tooltip using keyboard shortcuts.</p>
      </>
    );

    return (
      <DevelopmentPageTemplate
        title="Tooltips"
        overview={overview}
        codes={codes}
        accessibilitySection={accessibilitySection}
      />
    );
  }
}
